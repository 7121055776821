import axios from 'axios'
import store from "@/store/store_token_info";

export class Verificar {

    static async verificarCPF (cpf) {
        return axios.post('verificar/cpf/'+cpf);
    }
    static async verificarCNPJ (cnpj) {
        return axios.post('verificar/cnpj/'+cnpj);
    }
    static async verificarJaExisteCPF (cpf) {
        return axios.get('verificar/cadastrado/cpf/'+cpf);
    }
    static async verificarJaExisteEmail (email) {
        return axios.get('verificar/cadastrado/email/'+email);
    }


    //verificar permissao
    static async AcessoLocal (modulo) {
        let acesso = {
            visualizar:1,
            cadastrar:1,
            editar:1,
            deletar:1, 
        };
        for (const el of store.permissao) {
            if(el.permissao_modulo_id == modulo){
              if(el.acesso){
                acesso.visualizar = el.acesso.visualizar;
                acesso.cadastrar = el.acesso.cadastrar;
                acesso.editar = el.acesso.editar;
                acesso.deletar = el.acesso.deletar;
              }
            }
        }
        

        return acesso;
    }
}